<template>
    <page-layout-nolo :title="$t('forgotYourPassword')">

        <v-container>
            <v-card class="mb-10 py-2 px-sm-2" v-if="unsent">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <p class="nolo-body">{{ $t('label.enterAssociatedEmail') }}</p>
                            <v-form v-model="isValid">
                                <v-text-field outlined
                                        type="email" required autocomplete="email"
                                        :rules="rules"
                                        v-model="email" :label="$t('label.email')" />
                            </v-form>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-btn outlined block color="secondary" @click="cancel">{{$t('btn.cancel')}}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="5">
                            <v-btn block :disabled="!isValid" color="primary" @click="forgot">{{$t('btn.forgotPassword')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mb-10 py-2 px-sm-2" v-else>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <p class="nolo-body">{{$t('message.resetEmailSent')}}</p>
                        </v-col>
                        <v-col cols="12" sm="6" md="5">
                            <v-btn block color="primary" @click="$router.back()">{{$t('btn.backToLogin')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>

    </page-layout-nolo>
</template>

<script>
    import APICustomer from '../../api/APICustomer';
    import InputRules from "../../plugins/InputRules";

    export default {
        name: "YourAccount",
        data() {
            return {
                unsent: true,
                isValid: true,
                loading: false,
                email: '',
                url: window.location.origin + "/" + this.$router.resolve('recover-password').href,
            }
        },
        mounted() {
            if (typeof this.$route.params.email !== 'undefined') {
                this.email = this.$route.params.email;
            }
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            forgot() {
                const me = this;
                me.loading = true;
                APICustomer.forgotPassword(this, this.email, this.url)
                    .then(()=> {
                        me.unsent = false;
                    })
                    .finally(() => {
                        me.loading = false;
                    });
            }
        },
        computed: {
            rules() {
                return [InputRules.requiredEmail, InputRules.email];
            }
        }
    }
</script>

<style scoped>

</style>
